import { Helmet } from "react-helmet";
import * as React from "react";
import "../shared/styles/site.scss";

const pageStyles = {
    color: "#232129",
    // padding: "96px",
    fontFamily: "-apple-system, Roboto, sans-serif, serif",
  }

  const headerStyles = {
      textAlign: "center",
      marginBottom: "40px",
      marginTop: "30px"
  };

  function createMarkup() {
    return {__html: `
        <script>test()</script>
    `};
  }

  const LucBookingPage = () => {
      return (
          <>
          <Helmet>
            {/* <script src="https://app.qloq.be/js/widget-local.js?v=18"></script> */}
            <script src="https://app.qloq.be/js/widget.js?v=19"></script>
            <script>{ `function render() { 
                            console.log("Attempting...");
                            var isReady = typeof initiateWidget == "function";
                            if (isReady) {
                                initiateWidget('216ec7bb-d60b-44a0-9f74-b9362714659c');
                            }
                            else { 
                                setTimeout(render, 100);
                            }
                        }
                render()
                `}</script>
          </Helmet>
            <main style={pageStyles}>
                <h2 style={headerStyles}>Luc Van Quickelberge: Boek online of onsite een afspraak</h2>
                <div id="qloqWidget"></div>
                
                <Helmet>
                    
                </Helmet>
            </main>
          </>
          
      );
  }

  export default LucBookingPage 

  // <script>{`console.log('test'); initiateWidget('216ec7bb-d60b-44a0-9f74-b9362714659c');` }</script>